import React from 'react';
import {Link} from "react-router-dom";

const ThankYou = () => {

    return (
        <>
            <div className="page-wrapper form-view">
                <div className="sign-up-form-wrapper">
                    <div className="info-wrap full">
                        <div className="logo">UPGIANT</div>
                        <div className="left-heading">Thank you for choosing UpGiant! You can now login and create your first notification</div>
                        <a className='login-link' href='https://upgiant.com/'>Go to login page</a>
                </div>
                </div>
            </div>
        </>
    );
}

export default ThankYou;
