import React, {useContext, useRef, useState} from 'react';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import userContext from "../context/user-context";

const {APP_CONFIG} = window;

const Step2 = () => {

    const {user, setUser} = useContext(userContext);
    let navigate = useNavigate();
    const errorRef = useRef();
    const [searchParams] = useSearchParams();
    const user_email = searchParams.get("email");
    
    const handleSubmit = e => {
        e.preventDefault();
        errorRef.current.innerHTML = '';
        setUser({
            ...user,
            firstName: e.target.first_name.value,
            lastName: e.target.last_name.value,
            email: e.target.email.value,
            password: e.target.password.value,
        });

        let formData = {
            firstName: e.target.first_name.value,
            lastName: e.target.last_name.value,
            email: e.target.email.value,
            password: e.target.password.value,
        };

        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");


        fetch(APP_CONFIG.PATH + '/auth/signup', {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(formData),
            redirect: 'follow'
        })
            .then(result => result.json().then(data => ({status: result.status, body: data})))
            .then(result => {
                if (result.status === 200) {
                    setUser({
                        ...user,
                        accessToken: result.body.accessToken
                    });
                    navigate('/step-3?first_name=' + e.target.first_name.value + '&access_token=' + result.body.accessToken);
                } else {
                    errorRef.current.innerHTML = JSON.stringify(result.body.message);
                }
            })
            .catch(error => console.log('error', error));
    };


    return (
        <>
            <div className="page-wrapper form-view">
                <div className="sign-up-form-wrapper">
                    <div className="info-wrap">
                        <div className="logo">UPGIANT</div>
                        <div className="left-heading">Increase your conversion rates automatically</div>
                        <ul className="pros-list">
                            <li>✅ Easy setup, no coding required</li>
                            <li>✅ Anonymize events to showcase as automatic social proof</li>
                            <li>✅ ~33% average conv boost 🚀</li>
                        </ul>
                    </div>
                    <div className="sign-up-button-wrap">
                        <h1>Sign up for free</h1>
                        <form className="sign-up-form" action="" onSubmit={handleSubmit}>
                            <input type="text" name="first_name" placeholder='First Name *'/>
                            <input type="text" name="last_name" placeholder='Last Name *'/>
                            <input type="email" name="email" defaultValue={user_email==null?"":user_email} placeholder='Email address *'/>
                            <input type="password" name="password" placeholder='Password *'/>
                            <div className="password-description">8 characters min. One uppercase. One lowercase.</div>
                            <div className="form-post-text">By signing up, you agree to UpGiant’s <Link to=''>terms of
                                service</Link> and <Link to="#">privacy policy.</Link></div>
                            <div className="error" ref={errorRef}></div>
                            <button type='submit'>Get started</button>
                            <div className="after-text">No credit card required. Cancel anytime.</div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Step2;
