import React from 'react';
import GoogleIcon from '../assets/google.svg'
import {Link,useSearchParams} from "react-router-dom";

const Step1 = () => {
    const [searchParams] = useSearchParams();
    const user_email = searchParams.get("email");
    return (
        <>
            <div className="page-wrapper form-view">
                <div className="sign-up-form-wrapper">
                    <div className="info-wrap">
                        <div className="logo">UPGIANT</div>
                        <div className="left-heading">Increase your conversion rates automatically</div>
                        <ul className="pros-list">
                            <li>✅  Easy setup, no coding required</li>
                            <li>✅  Anonymize events to showcase as automatic social proof</li>
                            <li>✅  ~33% average conv boost 🚀</li>
                        </ul>
                    </div>
                    <div className="sign-up-button-wrap">
                        <h1>Sign up for free</h1>
                        <div className="buttons-wrap">
                            <a href="https://api.upgiant.com/auth/google?isSignUp=true" className='button-link google-link'>
                                <img src={GoogleIcon} alt="Google icon"/>
                                Sign in with Google
                            </a>
                            <div className="divider">or</div>
                            <Link className='button-link' to={user_email==null?"/step-2":"/step-2?email="+user_email}>Sign up with Email</Link>
                            <div className="have-acc-text">
                                Already have an account?
                                <Link to="#">Login</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Step1;
