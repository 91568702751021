import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import userContext from "../context/user-context";


const Step3 = () => {
    const [selectedRole, setSelectedRole] = useState('');
    const {user, setUser} = useContext(userContext);

    const [searchParams] = useSearchParams();

    const first_name = searchParams.get("first_name");
    const access_token = searchParams.get("access_token");

    let navigate = useNavigate();

    useEffect(() => {
        setUser({
            ...user,
            accessToken: user.accessToken ? user.accessToken : access_token
        });
    }, [])

    const roles = [
        {id: 0, title: 'Sales team', value: 'SALES_OPS'},
        {id: 1, title: 'Recruiting team', value: 'HR_RECRUITING_OPS'},
        {id: 2, title: 'Business owner', value: 'BUSINESS_OWNER'},
        {id: 3, title: 'Software engineer', value: 'ENGINEERING'},
        {id: 4, title: 'Other', value: 'OTHER'},
        {id: 5, title: 'Project manager', value: 'PROJECT_MANAGEMENT'},
        {id: 6, title: 'Data scientist', value: 'DATA_SCIENCE'},
        {id: 7, title: 'Management team', value: 'PROJECT_MGMT'},
        {id: 8, title: 'Marketing team', value: 'MARKETING_MARKETING_OPS'},
        {id: 9, title: 'Customer support', value: 'CUSTOMER_SUPPORT'},
        {id: 10, title: 'Information technology', value: 'IT'},
    ];

    const handleRoleClick = value => {
        setSelectedRole(value);

        setUser({
            ...user,
            role: value
        });
       navigate('/step-4');
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="sign-up-roles-wrapper">
                    <div className="head-wrap">
                        <h1>Hey {first_name}, customize your experience by telling us about yourself</h1>
                        <p>Select the role that best describes you (required)</p>
                    </div>
                    <div className="role-items">
                        {roles.map((role) => (
                            <div className={`role-item ${selectedRole === role.title ? 'selected' : ''}`} key={`role-${role.id}`} onClick={() => { handleRoleClick(role.value)}}>{role.title}</div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Step3;
