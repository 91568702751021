import './App.scss';
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import Step5 from "./components/Step5";
import {Routes, Route} from "react-router-dom";
import userContext from "./context/user-context";
import {useState} from "react";
import ThankYou from "./components/ThankYou";
import TagManager from 'react-gtm-module'



const App = () => {

    const initState = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        role: '',
        employees: '',
        platform: '',
        accessToken: ''
    };
    const tagManagerArgs = {
        gtmId: 'GTM-N6WBHM'
    }
    
    TagManager.initialize(tagManagerArgs)
    const [user, setUser] = useState(initState);

    return (
        <>
            <userContext.Provider value={{user, setUser}}>
                <Routes>
                    <Route path="/" element={<Step1/>}/>
                    <Route path="step-2" element={<Step2/>}/>
                    <Route path="step-3" element={<Step3/>}/>
                    <Route path="step-4" element={<Step4/>}/>
                    <Route path="step-5" element={<Step5/>}/>
                    <Route path="thank-you" element={<ThankYou/>}/>
                </Routes>
            </userContext.Provider>
        </>
    );
}

export default App;
