import React, {useContext, useState} from 'react';
import authorAvatar from '../assets/author-avatar.png'
import {useNavigate} from "react-router-dom";
import userContext from "../context/user-context";

const Step4 = () => {
    const [selectedEmployee, setSelectedEmployee] = useState('');
    let navigate = useNavigate();

    const {user, setUser} = useContext(userContext);

    const employees = [
        {id: 1, numbers: '1 - 49'},
        {id: 2, numbers: '250 - 999'},
        {id: 3, numbers: '50 - 249'},
        {id: 4, numbers: '1000+'},
    ];

    const handleEmployeeClick = numbers => {
        setSelectedEmployee(numbers);

        setUser({
            ...user,
            employees: numbers
        });
        navigate('/step-5');
    }

    return (
        <>
            <div className="page-wrapper lightwhite">
                <div className="sign-up-two-parts-wrapper">
                    <div className="top-level">
                        <div className="head-wrap">
                            <h1>How many employees does your company have?</h1>
                        </div>
                        <div className="employee-items">
                            {employees.map((employee) => (
                                <div className={`employee-item ${selectedEmployee === employee.numbers ? 'selected' : ''}`} key={`employee-${employee.id}`} onClick={() => { handleEmployeeClick(employee.numbers)}}>{employee.numbers}</div>
                            ))}
                        </div>
                    </div>
                    <div className="bottom-level">
                        <div className="quote-wrap">
                            <div className="quote">
                                <span className="open-quote">“</span>
                                <span className="close-quote">”</span>
                                “Helped us automatically increase conversion rates. 100% recommend.”
                            </div>
                            <div className="author-wrap">
                                <img src={authorAvatar} alt="Author avatar"/>
                                <div className="author-info">
                                    <div className="name">Devan Patel</div>
                                    <div className="position">VP of Strategy, Strut</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Step4;
