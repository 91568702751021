import React, {useContext, useRef, useState} from 'react';
import klaviyo from '../assets/klaviyo.png';
import shopify from '../assets/shopify.png';
import unbounce from '../assets/unbounce.png';
import authorAvatar from '../assets/lauren-avatar.png'
import {Link, useNavigate} from "react-router-dom";
import userContext from "../context/user-context";


const Step5 = () => {
    const [selectedPlatform, setSelectedPlatform] = useState('');
    const {user, setUser} = useContext(userContext);

    const {APP_CONFIG} = window;

    const errorRef = useRef();

    let navigate = useNavigate();

    const platforms = [
        {id: 2, title: 'shopify', img: shopify, value: 'SHOPIFY'},
        {id: 3, title: 'unbounce', img: unbounce, value: 'UNBOUNCE'},
        {id: 1, title: 'klaviyo', img: klaviyo, value: 'KLAVIYO'},
    ];

        const handlePlatformChange = value => {
        setSelectedPlatform(value)

        setUser({
            ...user,
            platform: value
        });
    };

    const handleSubmit = () => {

        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+user.accessToken);

        const formData = {
            roleOfUser: user.role,
            numberOfEmployees: user.employees,
            appUsed: user.platform
        };

        fetch(APP_CONFIG.PATH + '/auth/profile', {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify(formData),
            redirect: 'follow'
        })
            .then(result => result.json().then(data => ({status: result.status, body: data})))
            .then(result => {
                if (result.status === 200) {
                    navigate('/thank-you');
                } else {
                    errorRef.current.innerHTML = JSON.stringify(result.body.message);
                }
            })
            .catch(error => console.log('error', error));
    };


    return (
        <>
            <div className="page-wrapper lightwhite">
                <div className="sign-up-two-parts-wrapper">
                    <div className="top-level">
                        <div className="head-wrap">
                            <h1>What apps do you use?</h1>
                        </div>
                        <div className="apps-wrap">
                            {platforms.map((platform) => {
                                return (
                                    <div className={`app-item ${selectedPlatform === platform.title ? 'selected' : ''}`} key={`platform-${platform.id}`} onClick={() => {handlePlatformChange(platform.value)}}>
                                        <img src={platform.img} alt={`${platform.title} logo`}/>
                                        {platform.title}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="error" ref={errorRef}></div>
                        <Link className="more-platform" to="#">I don’t see my platform</Link>
                        <button className="submit-link" onClick={handleSubmit}>Next</button>
                    </div>
                    <div className="bottom-level">
                        <div className="quote-wrap">
                            <div className="quote">
                                <span className="open-quote">“</span>
                                <span className="close-quote">”</span>
                                “Easy way to increase conversion rates. Social proof popup notifications give shoppers confidence to purchase too.”
                            </div>
                            <div className="author-wrap">
                                <img src={authorAvatar} alt="Author avatar"/>
                                <div className="author-info">
                                    <div className="name">Lauren Johnson</div>
                                    <div className="position">Co-Founder, Berrylemon</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Step5;
